import * as React from 'react';

import MainLayout from '../layouts/MainLayout';
import { Seo, Box, Text } from '../components';

const NotFoundPage = () => {
  return (
    <MainLayout>
      <Seo title="404: Not Found" />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        minHeight="50vh"
        p={4}
        textAlign="center"
      >
        <Text variant="h1" color="accent">
          404: Not Found
        </Text>
        <Text>You just hit a route that doesn&#39;t exist!!!</Text>
      </Box>
    </MainLayout>
  );
};

export default NotFoundPage;
